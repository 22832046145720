/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import MostReusableTranslationI18nEn from '@/app/ui-new/i18n/MostReusableTranslation.i18n.en'
import View from '@/app/ui-new/pages/schedule-pickup/New/View.i18n.en'
export default {
    'New Order': 'New Order',
    'Bill To Account Number':'Bill To Account Number',
    'Destination Delivery Instructions':'Destination Delivery Instructions',
    'Total Declared Value':'Total Declared Value',
    'Close': 'Close',
    'Schedule Pickup Succeeded': 'Schedule Pickup Succeeded',
    'Schedule Pickup Failed': 'Schedule Pickup Failed',
    'There is an error occurred in the process, please contact support for solution.': 'There is an error occurred in the process, please contact support for solution.',
    'Congrats! You have successfully placed your schedule pickup.': 'Congrats! You have successfully placed your schedule pickup.',
    'Error message': 'Error message',
    'Schedule Pickup is disabled on Demo Sandbox': 'Schedule Pickup is disabled on Demo Sandbox',
    ...MostReusableTranslationI18nEn,
    ...View
}
